@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

html {
  font-family: 'Quicksand';
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #1C1E2E;
}

body::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 20px;
  border: 3px solid #1C1E2E;
}